@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Agdasima:wght@400;700&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

body{
  background-color: #ffffff;
}

.homecontent{
  padding: 1rem;
  font-family: "Agdasima", sans-serif;
  font-style: normal;
  background: url(/src/images/Cloud_1.jpg);
  color:  #ffffff;
  background-size: cover; /* Ensure the image covers the entire div */
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  background-blend-mode: luminosity;
}

.homecard{
  justify-content: space-evenly;
}

.homecontentcard{
  padding-top: 4%;
  padding-bottom: 14%;
  width: 50%;
  height: inherit;
  font-weight: 500;
}

.imgStyle{
  width: 50%;
  height: 50%;
  border-radius: 75px;
  padding-bottom: 1rem;
}

.homeprinciples {
  padding:0;
  margin:0;
  gap:0;
  width: 50%
}