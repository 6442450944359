@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

/* #54b949; */
.applogo {
  width: 50px;
  background: #040b11;
}

.headerlogotitle{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar {
  padding: .5rem;
  display: flex;
  justify-content: space-between;
  background-color: #040b11;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.navlinks {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  gap: 2rem;
  margin-right: 50px;
  padding-top: 1rem;
}

.headertext{
  color:#ffffff;
  font-family: 'Audiowide','Roboto';;
  font-size: small;
  margin:0;
}
