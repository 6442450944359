@import url('https://fonts.googleapis.com/css2?family=Agdasima:wght@400;700&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

.navbar {
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  justify-content: space-between;
  background-color: black;
}

h4{
  font-weight: bold;
  padding-bottom: 1rem;
}

ul, h4{
  font-size:large;
  color: white;
  font-family: 'Agdasima','Roboto';
}

.applogo {
  height: 100px;
}